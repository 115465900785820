<template>
  <div>
    <v-container>
      <h1 class="text-center">
        Tax <span class="underline-text">Harvesting</span>
      </h1>
      <p class="text-center mt-3 mb-10" style="color: #909090">
        Check how your portfolio fare today
      </p>
      <div class="d-flex gap-r align-center justify-center">
        <div
          class="elevation-3 white pa-3"
          style="width: 300px; border-radius: 10px"
        >
          <p>Current Value</p>
          <h1 style="font-family: 'Poppins'">0$</h1>
        </div>
        <div
          class="elevation-3 white pa-3 ml-5"
          style="width: 300px; border-radius: 10px"
        >
          <p>Selling Value</p>
          <h1 style="font-family: 'Poppins'">0$</h1>
        </div>
        <div
          class="elevation-3 white pa-3 ml-5"
          style="width: 300px; border-radius: 10px"
        >
          <div class="d-flex">
            <p class="ma-0">Tax :</p>
            <h3 style="font-family: 'Poppins'" class="ml-5">0$</h3>
          </div>
          <div class="d-flex mt-4">
            <p class="ma-0">Rate :</p>
            <h3 style="font-family: 'Poppins'" class="ml-5"> 30%</h3>
          </div>
        </div>
      </div>
      <div class="white pa-5 mb-10 mt-10">
        <v-simple-table class="no-lines-table">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">#</th>
                <th class="text-left"></th>
                <th class="text-left">Exchange</th>
                <th class="text-left">Coin</th>
                <th class="text-left">Current Price</th>
                <th class="text-left">Quantity</th>
                <th class="text-left">Total Price</th>
                
                <th class="text-left"></th>
              </tr>
            </thead>
            <tbody class="no-lines-body">
              <tr
                v-for="(item, index) in COIN_BALANCE"
                :key="item.name"
                class=""
                style="font-family: 'Poppins', sans-serif"
              >
                <td class="py-6">{{ index + 1 }}</td>
                <td>
                  <v-checkbox color="teal"></v-checkbox>
                </td>
                <td>
                  <div class="d-flex align-center">
                    <img
                      :src=getExchangeLogoUrl(item.exchange)
                      class="rounded-circle"
                      width="40"
                      height="40"
                    />
                    <div class="ms-3">
                      <p class="ma-0">{{item.exchange}}</p>
                      <!-- <span class="fw-normal" style="color: #A6A6A6;"></span> -->
                    </div>
                  </div>
                </td>
                <td>
                  <p>
                    <i class="cc mx-1" :class="item.coin"></i>{{ item.coin }}
                  </p>
                </td>
                <td><p>$ --</p></td>

                <td>
                  <p>
                    {{ item.coinsRemaining }}
                  </p>
                </td> 
                <td><v-btn small outlined rounded color="teal">sell</v-btn></td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </v-container>
  </div>
</template>
  
  <script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["COIN_BALANCE"]),
  },
  methods: {
    getExchangeLogoUrl(exchangeName) {
      return require(`@/assets/icons/${exchangeName
        .toLowerCase()
        .replace(" ", "")}.png`);
    },
  },
};
</script>
  
  <style scoped>
p {
  margin: 0px;
}
no-lines-table table,
.no-lines-table tbody,
.no-lines-table tr,
.no-lines-table td {
  border: none !important;
}

.no-lines-row {
  border-bottom: none !important;
}

.underline-text {
  position: relative;
  display: inline-block;
  color: #4aabab;
}
.underline-text::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: -20px;
  right: 0;
  stroke: 3px;
  width: 210px;
  height: 25px;
  background-image: url("../assets/welcome.png");
  /* filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25)); */
  background-repeat: no-repeat;
  background-size: cover;
}
</style>